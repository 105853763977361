<script setup lang="ts"></script>
<template>
  <div>
    <div class="border-b">
      <div
        class="mx-auto flex h-24 max-w-[90rem] items-center border-black/25 px-4"
      >
        <NuxtLink to="/">
          <SharedTheIcon icon-name="logo" style="width: 121px" />
        </NuxtLink>
      </div>
    </div>

    <div class="mx-auto max-w-[90rem] px-4">
      <slot />
    </div>
  </div>
</template>
